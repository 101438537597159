#range-slider {
    margin: auto;
    height: 60px;
    background-color: #4a484a;
    overflow: hidden; 
    position:relative
  }
  #range-slider .range-slider__thumb {
    width: 2px;
    height: 38px;
    border-radius: 4px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E")
      #fff;
    background-repeat: no-repeat;
    background-position: center;
  }
  #range-slider .range-slider__range {
    border-radius: 6px;
    /* border: 1px solid green; */
    box-sizing: border-box;
  }
  #range-slider .range-slider{
    height:60px;
    position:absolute
  }
  .range-slider.test .range-slider__range{
    background-color: blue;
  }

  .console .MuiOutlinedInput-notchedOutline{
    border-color: white !important;
 }

 .console .MuiSelect-iconOutlined{
  color: white !important;
 }

 .console .MuiOutlinedInput-input{
  color: white !important;
 }

 .slider-container::-webkit-scrollbar {
  height: 18px;               /* width of the entire scrollbar */
}

.slider-container::-webkit-scrollbar-track {
  background: #4a484a;        /* color of the tracking area */
}

.slider-container::-webkit-scrollbar-thumb {
  background-color: #CDCDCC;    /* color of the scroll thumb */
  border: 3px solid #4a484a;  /* creates padding around scroll thumb */
}