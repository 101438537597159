.seperator {
    width: 80px;
    height: 80px;
    align-self: center;
  }

  .title {
    color: #ffffff;
    font-size: 35px !important;
    font-family:"DM Sans" !important;
  } 
  
  .subtitle {
    color: white;
    margin-left: 10px !important;
    font-family:"DM Sans" !important; 
  }
  
  /* Apply when the viewport is less than 600px */
  @media (max-width: 600px) {
    .seperator {
      width: 20px;
      height: 20px;
    }
    .title {
      font-size: 25px !important;
    }
    .subtitle {
      font-size: 15px !important;

    }

  }