.rhap_container {
    background-color: inherit;
    padding: 0px;
    box-shadow: none;
}

.rhap_stacked .rhap_controls-section {
    margin-top: 0px;
}

