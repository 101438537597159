.cloneModal .filepond--wrapper {
    margin-left: 20px;
}

.CloneModal {
    position: absolute;
    width: 50vw;
    max-height: 90vh;
    min-height: 30vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272727;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 5px;
    transition: opacity 500ms ease-in-out;
  }
  