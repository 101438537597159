.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(39, 36, 36, 0.75); */
    background-color: rgba(132, 131, 131, 0.75);
    transition: opacity 500ms ease-in-out;
    z-index: 3;

  }
  
  .Modal {
    position: absolute;
    width: 95vw;
    max-height: 95vh;
    min-height: 30vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272727;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 5px;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .Modal.ReactModal__Content--after-open {
    opacity: 1;
  }
  
  .Modal.ReactModal__Content--before-close {
    opacity: 0;
  }
  

  .Modal::-webkit-scrollbar {
    width: 18px;               /* width of the entire scrollbar */
  }
  
  .Modal::-webkit-scrollbar-track {
    background: #4a484a;        /* color of the tracking area */
  }
  
  .Modal::-webkit-scrollbar-thumb {
    background-color: #CDCDCC;    /* color of the scroll thumb */
    border: 3px solid #4a484a;  /* creates padding around scroll thumb */
  }

  .ContextModal {
    position: absolute;
    width: 50vw;
    max-height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272727;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 5px;
    transition: opacity 500ms ease-in-out;
    padding-bottom: 20px;
  }
  